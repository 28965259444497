var CHECKTOUCH = (function($) {	

	function isTouchDevice() {
		return 'ontouchstart' in window
		|| navigator.maxTouchPoints;
	};

	function checkTouchDevice() {
		if(isTouchDevice()) {
			$('body').addClass('touch-device');
		}
	}	

	function init() {	

		checkTouchDevice();

	}


	return {
		init: init
	}

})(jQuery);