var DOSSIERNAV = (function($) {	

	function addDossierNavListeners() {
		var scrollMargin;

		if($('#wpadminbar').length) {
			scrollMargin = 64;
		} else {
			scrollMargin = 32;
		}

		$(".dossier-nav__links").find('a').on('click', function(e) {
			e.preventDefault();
			
			$("html, body").stop().animate({
				scrollTop: $( $.attr(this, 'href') ).offset().top - $('.header').outerHeight() - scrollMargin
			}, 500, 'swing');
		});
	}

	function init() {	

		addDossierNavListeners();

	}


	return {
		init: init
	}

})(jQuery);