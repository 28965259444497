var CONTACTFORM = (function($) {	

	function addInputListener() {
		var $input = $('.wpcf7-form-control');

		$input.on('focus', function() {
			$(this).closest('label').addClass('active');
		});

		$input.on('blur', function() {
			if($(this).val() == '') {
				$(this).closest('label').removeClass('active');
			}
		});
	}

	function init() {	

		addInputListener();

	}


	return {
		init: init
	}

})(jQuery);