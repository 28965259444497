var TABS = (function($) {	

	function tabListener(i, tabs) {
		var tabs = tabs;		
		var tabContent = tabs.find('.tabs__content-item');	

		return function(e) {					
			e.preventDefault();
			var $this = $(this);

			tabs.find('.tabs__labels>a').removeClass('active');
			tabs.find('.tabs__content-item').removeClass('active');	
			tabs.find('.tabs__content-item:nth-child('+(i+1)+')').addClass('active');	
			$this.addClass('active');
			
		}
	}

	function createListeners() {

		var $tabs = $('.block--tabs');

		for(var i = 0;i < $tabs.length;i++) {

			var tabs = $($tabs[i]);
			var $tabLinks = tabs.find('.tabs__labels>a');	

			for(var j = 0;j < $tabLinks.length;j++) {

				$($tabLinks[j]).on('click', tabListener(j, tabs));
			}
		}

	}

	

	function init() {	

		createListeners();

		$('.tabs__content-item:nth-child(1)').addClass('active');
		$('.tabs__labels a:nth-child(1)').addClass('active');	

	}


	return {
		init: init
	}

})(jQuery);