var LAZYLOADING = (function($) {	

	function initIE11() {
		var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

		if(isIE11) {
			document.addEventListener('lazyloaded', function(e) {
				var target = $(e.target);

				if(target.parent('picture').hasClass('bg-picture')) {
			    	objectFitPolyfill(target);
			    }
			});
		}
	}
	

	

	function init() {	

		window.lazySizesConfig = window.lazySizesConfig || {};
		window.lazySizesConfig.init = false;

		initIE11();

	}


	return {
		init: init
	}

})(jQuery);