var SOCIALSHARING = (function($) {	

	function addSocialListeners() {
		$('.social-icon--facebook').on('click', function(e) {
			e.preventDefault();
			window.open("https://www.facebook.com/sharer/sharer.php?u="+window.location.href, "newwindow", "width=400, height=600");
		});

		$('.social-icon--twitter').on('click', function(e) {
			e.preventDefault();
			window.open("https://twitter.com/intent/tweet?text="+$('meta[name="description"]').attr('content')+"&url="+window.location.href, "newwindow", "width=400, height=600");
		});

		$('.social-icon--googleplus').on('click', function(e) {
			e.preventDefault();
			window.open("https://plus.google.com/share?url="+window.location.href, "newwindow", "width=400, height=600");
		});  

		$('.social-icon--linkedin').on('click', function(e) {
			e.preventDefault();
			window.open("http://www.linkedin.com/shareArticle?mini=true&url="+window.location.href, "newwindow", "width=400, height=600");
		}); 
	}

	

	function init() {	

		addSocialListeners();

	}


	return {
		init: init
	}

})(jQuery);