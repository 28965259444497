var SLIDER = (function($) {	

	function initSlick() {
		$('.slider').slick({
			slidesToShow: 4,
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	}

	function init() {	

		initSlick();

	}


	return {
		init: init
	}

})(jQuery);