jQuery(document).ready(function ($) {
  var $body = $("body");
  var $tabs = $(".block--tabs");
  var $socialIcons = $(".social-icons");
  var $dossierNav = $(".dossier-nav");
  var $schadeMeldenIframe = $("#schade-melden-iframe");
  var $slider = $(".slider");
  var $contactform = $(".wpcf7");

  // Sometimes
  if ($body.hasClass("home")) {
    HOME.init();
  }

  if ($tabs.length) {
    TABS.init();
  }

  if ($socialIcons.length) {
    SOCIALSHARING.init();
  }

  if ($dossierNav.length) {
    DOSSIERNAV.init();
  }

  if ($schadeMeldenIframe.length) {
    $schadeMeldenIframe.iFrameResize();
  }

  if ($slider.length) {
    SLIDER.init();
  }

  if ($contactform.length) {
    CONTACTFORM.init();
  }

  if (document.querySelector(".announcement")) {
    ANNOUNCEMENT.init();
  }

  // Always
  MENU.init();
  LOGIN.init();
  CHECKTOUCH.init();
  LAZYLOADING.init();
  HEADER.init();
});

jQuery(window).load(function () {
  MENU.setMenuHeight();
  lazySizes.init();

  jQuery(".cookie-consent").addClass("load");
});
