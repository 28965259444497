var LOGIN = (function($) {	

	var $loginModal = $('.login-modal');

	function addFormListener() {
		$('form#login').on('submit', function(e){
		    e.preventDefault();
		    $('form#login p.status').show().text("Laden...");
		    $.ajax({
		        type: 'POST',
		        dataType: 'json',
		        url: ajaxUrl,
		        data: { 
		            'action': 'ajaxlogin', //calls wp_ajax_nopriv_ajaxlogin
		            'username': $('form#login #username').val(), 
		            'password': $('form#login #password').val(), 
		            'remember': $('form#login #remember').is(":checked"), 
		            'security': $('form#login #security').val() 
		        },
		        success: function(data){
		            $('form#login p.status').text(data.message);
		            if (data.error) {
		            	$('form#login').addClass('error');
		            }
		            if (data.loggedin) {
		                document.location.href = homeUrl + "/portal";
		            }
		        }
		    });
		    
		});
	}

	
	function openLoginModal() {
		$('.button--login.login').on('click', function(e) {
			e.preventDefault();

			$loginModal.toggleClass('active');
		});

		if($(window).width() < 992) {
			$('#main').find('.button--login').on('click', function() {
				$('.mobile-menu').toggleClass('active');
				$('.mobile-menu__button').toggleClass('active');
			});
		}
	}
	
	function closeOnBlur() {
		$('html').on('click', function(e) {
			if(!$(e.target).closest('.login-modal').length && !$(e.target).closest('.button--login.login').length) {    
				$loginModal.removeClass('active');
			} 
		});
	}

	

	

	function init() {	

		addFormListener();
		openLoginModal();
		closeOnBlur();

	}


	return {
		init: init
	}

})(jQuery);