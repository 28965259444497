var HOME = (function($) {

	var $playButton = $('.hero__video-button');
	var $body = $('body');
	var videoURL = $playButton.data('video-url');
	var html = '<div class="video-layer" style="display: none;"><div class="video-layer__bg">';
	html += '</div><div class="video-layer__close"></div><div class="video-wrap"><div class="video-wrapper">';
	html += '<iframe allow="autoplay; fullscreen" allowfullscreen="" frameborder="0" name="" sandbox="allow-scripts allow-same-origin allow-presentation allow-popups" scrolling="no" src="'+videoURL+'?iv_load_policy=3&amp;modestbranding=1&amp;rel=0&amp;autohide=1&amp;playsinline=1&amp;autoplay=1"></iframe>';
	html += '</div></div></div></div>';

	var $videoLayer;

	function closeVideo() {
		
		$videoLayer.fadeOut(function() {
			$videoLayer.remove();
		});
		$playButton.on('click', showVideo);		
	}

	function createCloseListener() {
		var $videobg = $('.video-layer__bg');
		var $closeButton = $('.video-layer__close');

		$videobg.on('click', closeVideo);
		$closeButton.on('click', closeVideo);
	}

	function showVideo() {
		$playButton.off('click');
		$body.append(html);
		$videoLayer = $('.video-layer');
		
		$videoLayer.css("display", "flex").hide().fadeIn();
		createCloseListener();
	}
	
	function init() {	

		$playButton.on('click', showVideo);

	}


	return {
		init: init
	}

})(jQuery);