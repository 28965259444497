var ANNOUNCEMENT = (function ($) {
  function init() {
    var announcement = document.querySelector(".announcement");
    var closeButton = document.querySelector(".announcement__close");

    let announcementClosed = sessionStorage.getItem("announcement_closed");

    if (!announcementClosed) {
      announcement.classList.add("is-active");

      closeButton.addEventListener("click", function () {
        announcement.classList.remove("is-active");
        sessionStorage.setItem("announcement_closed", true);
      });
    }
  }

  return {
    init: init,
  };
})(jQuery);
