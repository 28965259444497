var HEADER = (function ($) {
  function init() {
    var lastScrollTop = 0;
    const $header = $(".header");

    window.addEventListener(
      "scroll",
      function () {
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
          $header.addClass("is-collapsed");
        } else {
          $header.removeClass("is-collapsed");
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      },
      false
    );
  }

  return {
    init: init,
  };
})(jQuery);
