var MENU = (function($) {

	var $mobileMenu = $('.mobile-menu');
	var $window = $(window);
	
	function setMenuHeight() {
		var $windowHeight = $window.height();

		$mobileMenu.css('max-height', $windowHeight);
	}

	function subMenu() {
		$('.menu-item-has-children>a').on('click', function(e) {
			e.preventDefault();
			var menuLink = $(this).parent('.menu-item-has-children');
			
			if(menuLink.hasClass('open')) {
				menuLink.removeClass('open');
			} else {
				$('.menu-item-has-children').removeClass('open');
				menuLink.addClass('open');
			}
		});
	}

	function closeOnBlur() {

		$('html').on('click', function(e) {
			if(!$(e.target).closest('#menu-mainmenu').length && !$(e.target).closest('#menu-mainmenu-1').length) {    
				$('.menu-item-has-children').removeClass('open');
			}
			if(!$(e.target).closest('.mobile-menu').length && !$(e.target).closest('.mobile-menu__button').length && !$(e.target).closest('.button--login').length) {
				$mobileMenu.removeClass('active');
				$('.mobile-menu__button').removeClass('active');
			}
		});

	}


	function mobileMenuButton() {
		var $mobileMenuButton = $('.mobile-menu__button');

		$mobileMenuButton.on('click', function(e) {
			$mobileMenu.toggleClass('active');
			$mobileMenuButton.toggleClass('active');
		});	
	}
	
	function bindWindowResize() {
		$window.resize(function() {
			MENU.setMenuHeight();
		});
	}

	function init() {	

		subMenu();
		closeOnBlur();
		mobileMenuButton();	
		bindWindowResize();

	}


	return {
		init: init,
		setMenuHeight: setMenuHeight
	}

})(jQuery);
